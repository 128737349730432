<template>
  <div>
    <el-table
      :data="tableData.list"
      border
      @cell-click="clickIntegralData"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center' }"
      style="width: 100%"
    >
      <el-table-column label="序号" width="100">
        <template slot-scope="{ row }">{{ row.sid }}</template>
      </el-table-column>
      <el-table-column prop="name" label="活动名称">
        <template slot-scope="{ row }">{{ row.title }}</template>
      </el-table-column>
      <el-table-column label="变式数">
        <template slot-scope="{ row }">{{ row.arrange }}</template>
      </el-table-column>
      <el-table-column label="完成数">
        <template slot-scope="{ row }">{{ row.completed }}</template>
      </el-table-column>
      <el-table-column label="一次正确数量">
        <template slot-scope="{ row }">{{ row.onceCorrectCount }}</template>
      </el-table-column>
      <el-table-column label="二次正确数量">
        <template slot-scope="{ row }">{{ row.twiceCorrectCount }}</template>
      </el-table-column>
      <el-table-column label="两次都错">
        <template slot-scope="{ row }">{{ row.twiceWrongCount }}</template>
      </el-table-column>
      <el-table-column label="内化率">
        <template slot-scope="{ row }">{{ row.accuracy }}</template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header" slot-scope="slot">
          <el-dropdown @command="handleCommand" width="150">
            <span class="el-dropdown-link">
              排名
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" width="150">
              <el-dropdown-item command="0">完成数</el-dropdown-item>
              <el-dropdown-item command="1">一次正确</el-dropdown-item>
              <el-dropdown-item command="2">二次正确</el-dropdown-item>
              <el-dropdown-item command="3">二次都错</el-dropdown-item>
              <el-dropdown-item command="4">内化率</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="{ row }">{{ row.ranking }}</template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      layout="prev, pager, next"
      :current-page="activity_Page"
      :page-size="current_size"
      :total="tableData.total"
      @current-change="changeTableData"
      class="pager"
    ></el-pagination>
    <!-- 弹窗 -->
    <el-dialog title :visible.sync="showScreen" width="60%" :before-close="handleClose">
      <el-select
        v-model="value"
        placeholder="请选择"
        size="mini"
        @change="selectVariant"
        style="margin-bottom:20px"
      >
        <el-option
          v-for="item in selectVariantData"
          :key="item.activeId"
          :label="item.title"
          :value="item.activeId"
        ></el-option>
      </el-select>

      <el-table
        :data="studentDetailsData.list"
        border
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        style="width: 100%"
        @row-click="clickTabel"
      >
        <el-table-column prop="name" label="变式名称">
          <template slot-scope="{ row }">{{ row.caseSn }}</template>
        </el-table-column>

        <el-table-column label="一次正确">
          <template slot-scope="{ row }">
            <div v-if="row.state == 1">√</div>
          </template>
        </el-table-column>
        <el-table-column label="二次才正确">
          <template slot-scope="{ row }">
            <div v-if="row.state == 5">√</div>
          </template>
        </el-table-column>
        <el-table-column label="两次都错">
          <template slot-scope="{ row }">
            <div v-if="row.state == 3" @click="ComparativeStudy(row)">√</div>
          </template>
        </el-table-column>
        <el-table-column label="单个变式内化率">
          <template slot-scope="{ row }">{{ row.accuracy }}</template>
        </el-table-column>
      </el-table>

      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="particulars_Page"
        :page-size="current_size"
        :total="studentDetailsData.total"
        @current-change="changeTableDatas"
        class="pager"
      ></el-pagination>
      <el-dialog width="70%" :title="dialog_title" :visible.sync="insideDialog" append-to-body>
        <el-table :data="gridData" v-loading="student_loading" element-loading-text="拼命统计中">
          <el-table-column property="fullName" label="用户名"></el-table-column>
          <el-table-column property="taskCount" label="布置次数"></el-table-column>
          <el-table-column property="createTime" label="布置时间"></el-table-column>
          <el-table-column property="userAnswers" label="本次内化率" sortable></el-table-column>
          <el-table-column v-for="(item,index) in tableArr_student" :key="index" :label="item">
            <template slot-scope="{row}">
              <div
                v-for="(itm,inx) in row.children"
                :key="inx"
                v-if="item == itm.name"
              >{{itm.title==0?'×':'√'}}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import {
  SpecialListByStudentAnalysis,
  SpecialListByStudent,
  ResultByTaskId
} from "../../../api/api";
export default {
  props: {
    status: {
      type: Number
    },
    searchClassId: {
      type: [Number, String],
      default: 0,
    },
  },
  data () {
    return {
      //主页内容
      tableData: [],
      value: null, //下拉选择的
      orderStatus: 4,
      content: "内化率",
      showScreen: false, //是否显示弹窗
      //学生详情
      studentDetailsData: [],
      activity_Page: 1, //活动分页当前页
      current_size: 5,
      particulars_Page: 1, //活动详情页的分页
      selectVariantData: [], //进入详情后下拉
      insideDialog: false,
      student_loading: false,
      gridData: [],
      tableArr_student: [],
      dialog_title: ''
    };
  },
  methods: {
    clickTabel (val) {
      this.gridData = []
      this.insideDialog = true
      this.student_loading = true
      let param = {
        "pageNum": null,
        "pageSize": null,
        "taskId": val.taskId,
        'activityId': val.activeId,
        "classId": this.searchClassId,
        "sort": 1,
        "singleORall": 2,//1 查所有  2 查当前
      }
      ResultByTaskId(param).then(res => {
        this.student_loading = false
        if (res.code == 0) {
          console.log(res)
          res.data.list.map((listValue) => {
            listValue.userAnswers = (listValue.userAnswers * 100).toFixed(2) + '%'
            this.dialog_title = val.caseSn
            if (listValue.names) {
              this.tableArr_student = listValue.names
              listValue.children = []
              listValue.names.map((nameValue, nameIndex) => {
                let obj = {}
                listValue.answers.map((answerValue, answerIndex) => {
                  if (nameIndex == answerIndex) {
                    obj['name'] = nameValue
                    obj['title'] = answerValue
                    listValue.children.push(obj)
                  }
                })
              })
            }
          })
          this.gridData = res.data.list
          // this.pageDetails.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //初始化
    init () {
      this.activity_Page = 1;
      this.particulars_Page = 1; //活动详情页的分页
      this.SpecialList();
    },
    //获取活动统计接口数据
    SpecialList () {
      let param = {};
      if (this.$route.query.classId) {
        param = {
          pageNum: this.activity_Page,
          pageSize: this.current_size,
          classId: Number(this.$route.query.classId),
          orderStatus: this.orderStatus,
          userId: Number(this.$route.query.userId),
        };
      } else {
        param = {
          pageNum: this.activity_Page,
          pageSize: this.current_size,
          classId: this.searchClassId,
          orderStatus: this.orderStatus,
        };
      }
      SpecialListByStudentAnalysis(param).then((res) => {
        res.data.list.map((item) => {
          item.accuracy = (item.accuracy * 100).toFixed(2) + "%";
        });
        this.tableData = res.data;
      });
    },
    //点击排行跳转
    handleCommand (command) {
      this.orderStatus = command;
      if (this.orderStatus == 0) {
        this.content = "完成数";
      }
      if (this.orderStatus == 1) {
        this.content = "一次正确";
      }
      if (this.orderStatus == 2) {
        this.content = "二次正确";
      }
      if (this.orderStatus == 3) {
        this.content = "二次都错";
      }
      if (this.orderStatus == 4) {
        this.content = "内化率";
      }
      this.SpecialList();
    },
    //点击弹窗获取学生详情
    clickIntegralData (val) {
      this.showScreen = true; //activeId 活动id
      let param = {};
      if (this.$route.query.classId) {
        param = {
          classId: Number(this.$route.query.classId),
          orderStatus: this.orderStatus,
          userId: Number(this.$route.query.userId),
        };
      } else {
        param = {
          classId: this.searchClassId,
          orderStatus: this.orderStatus,
        };
      }

      SpecialListByStudentAnalysis(param).then((res) => {
        res.data.list.map((item) => {
          item.accuracy = (item.accuracy * 100).toFixed(2) + "%";
        });
        this.selectVariantData = res.data.list;
        this.value = val.activeId;
        this.SpecialListByStudent(this.value);
      });
    },
    //获取学生详情接口数据
    SpecialListByStudent (active) {
      let param = {};
      if (this.$route.query.classId) {
        param = {
          pageNum: this.particulars_Page,
          pageSize: this.current_size,
          activeId: active,
          userId: Number(this.$route.query.userId),
        };
      } else {
        param = {
          pageNum: this.particulars_Page,
          pageSize: this.current_size,
          activeId: active,
          orderStatus: this.orderStatus,
        };
      }

      SpecialListByStudent(param).then((res) => {
        this.studentDetailsData = res.data;
        res.data.list.map((item) => {
          item.accuracy = (item.accuracy * 100).toFixed(2) + "%";
        });
      });
    },
    //关闭弹窗
    handleClose (done) {
      this.particulars_Page = 1; //活动详情页的分页
      done();
    },
    //主页分页
    changeTableData (val) {
      this.activity_Page = val;
      this.SpecialList();
    },
    //详情分页
    changeTableDatas (val) {
      this.particulars_Page = val;
      this.SpecialListByStudent(this.value);
    },
    //对比学习
    ComparativeStudy (val) {
      var id = {
        id: val.activeId,
        caseId: val.caseId,
      };

      if (this.status == 0) {//判断是不是k12老师
      } else {
        this.$router.push({ path: "/comparative", query: id });
      }

    },
    //选择变式活动
    selectVariant (command) {
      this.SpecialListByStudent(command);
    },
  },
  mounted () {
    this.init();
  },

  watch: {
    searchClassId (newV, oldV) {
      this.init();
    },
  },
};
</script>

<style lang='less' scoped>
@import '../../../style/mouse.less';
.pager {
  text-align: center;
  margin: 20px 0;
}
</style>