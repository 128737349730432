var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData.list,"border":"","cell-style":{ textAlign: 'center' },"header-cell-style":{ textAlign: 'center' }},on:{"cell-click":_vm.clickIntegralData}},[_c('el-table-column',{attrs:{"label":"序号","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.sid))]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"活动名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.title))]}}])}),_c('el-table-column',{attrs:{"label":"变式数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.arrange))]}}])}),_c('el-table-column',{attrs:{"label":"完成数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.completed))]}}])}),_c('el-table-column',{attrs:{"label":"一次正确数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.onceCorrectCount))]}}])}),_c('el-table-column',{attrs:{"label":"二次正确数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.twiceCorrectCount))]}}])}),_c('el-table-column',{attrs:{"label":"两次都错"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.twiceWrongCount))]}}])}),_c('el-table-column',{attrs:{"label":"内化率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.accuracy))]}}])}),_c('el-table-column',{attrs:{"width":"150"},scopedSlots:_vm._u([{key:"header",fn:function(slot){return [_c('el-dropdown',{attrs:{"width":"150"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" 排名 "),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown","width":"150"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"0"}},[_vm._v("完成数")]),_c('el-dropdown-item',{attrs:{"command":"1"}},[_vm._v("一次正确")]),_c('el-dropdown-item',{attrs:{"command":"2"}},[_vm._v("二次正确")]),_c('el-dropdown-item',{attrs:{"command":"3"}},[_vm._v("二次都错")]),_c('el-dropdown-item',{attrs:{"command":"4"}},[_vm._v("内化率")])],1)],1)]}},{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.ranking))]}}])})],1),_c('el-pagination',{staticClass:"pager",attrs:{"background":"","layout":"prev, pager, next","current-page":_vm.activity_Page,"page-size":_vm.current_size,"total":_vm.tableData.total},on:{"current-change":_vm.changeTableData}}),_c('el-dialog',{attrs:{"title":"","visible":_vm.showScreen,"width":"60%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.showScreen=$event}}},[_c('el-select',{staticStyle:{"margin-bottom":"20px"},attrs:{"placeholder":"请选择","size":"mini"},on:{"change":_vm.selectVariant},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.selectVariantData),function(item){return _c('el-option',{key:item.activeId,attrs:{"label":item.title,"value":item.activeId}})}),1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.studentDetailsData.list,"border":"","cell-style":{ textAlign: 'center' },"header-cell-style":{ textAlign: 'center' }},on:{"row-click":_vm.clickTabel}},[_c('el-table-column',{attrs:{"prop":"name","label":"变式名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.caseSn))]}}])}),_c('el-table-column',{attrs:{"label":"一次正确"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.state == 1)?_c('div',[_vm._v("√")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"二次才正确"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.state == 5)?_c('div',[_vm._v("√")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"两次都错"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.state == 3)?_c('div',{on:{"click":function($event){return _vm.ComparativeStudy(row)}}},[_vm._v("√")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"单个变式内化率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.accuracy))]}}])})],1),_c('el-pagination',{staticClass:"pager",attrs:{"background":"","layout":"prev, pager, next","current-page":_vm.particulars_Page,"page-size":_vm.current_size,"total":_vm.studentDetailsData.total},on:{"current-change":_vm.changeTableDatas}}),_c('el-dialog',{attrs:{"width":"70%","title":_vm.dialog_title,"visible":_vm.insideDialog,"append-to-body":""},on:{"update:visible":function($event){_vm.insideDialog=$event}}},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.student_loading),expression:"student_loading"}],attrs:{"data":_vm.gridData,"element-loading-text":"拼命统计中"}},[_c('el-table-column',{attrs:{"property":"fullName","label":"用户名"}}),_c('el-table-column',{attrs:{"property":"taskCount","label":"布置次数"}}),_c('el-table-column',{attrs:{"property":"createTime","label":"布置时间"}}),_c('el-table-column',{attrs:{"property":"userAnswers","label":"本次内化率","sortable":""}}),_vm._l((_vm.tableArr_student),function(item,index){return _c('el-table-column',{key:index,attrs:{"label":item},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.children),function(itm,inx){return (item == itm.name)?_c('div',{key:inx},[_vm._v(_vm._s(itm.title==0?'×':'√'))]):_vm._e()})}}],null,true)})})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }