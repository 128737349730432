<!-- 学生学情 -->
<template>
  <div>
    <el-button @click="goUpperLevel" v-fence="{ data: '', functions: ['创建群组'] }">
      <i class="el-icon-back" style="margin-right: 10px"></i>返回
    </el-button>
    <span
      style="margin-left: 30px"
      v-fence="{ data: '', functions: ['创建群组'] }"
    >学生：{{ studentDetails.fullName }}-{{ studentDetails.userName }}</span>
    <div class="screen" style="display: flex; align-items: center" v-if="showScreen">
      <span class="text">选择班级：</span>
      <div class="select">
        <el-select v-model="searchClassId" class="sel" size="mini" @change="selectClassId">
          <el-option
            v-for="item in classOptions"
            :key="item.id"
            :label="item.className"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select v-model="topicType" class="sel" size="mini" @change="changeType">
          <el-option
            v-for="item in topicTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="activityValue"
          class="sel"
          size="mini"
          @change="selectClassId"
          placeholder="请选择活动"
          v-fence="{ data: '', functions: ['IMX做题'] }"
          v-if="topicType==1"
        >
          <el-option
            v-for="item in activityOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <!-- <div id="student-tree" v-if="topicType == 0 && status==1"></div> -->
    <!-- <div :class="topicType==0&&status==1?'card-header-external':''">
      <div :class="status==1&&topicType==0?'card-header-student':'card-header'">
        <el-card class="box-card" @click.native="noCompleted">
          <div class="card-icon">
            <img src="../../assets/icon/surface.png" alt />
          </div>
          <span class="text">
            <span>{{studentSurvey.underway||0}}</span>
            <i>任务进行中</i>
          </span>
        </el-card>
        <el-card class="box-card" @click.native="completed">
          <div class="card-icon">
            <i class="el-icon-s-platform"></i>
          </div>
          <span class="text">
            <span>{{studentSurvey.accomplish||0}}</span>
            <i>任务已完成</i>
          </span>
        </el-card>
        <el-card class="box-card">
          <div class="card-icon">
            <i class="el-icon-s-order"></i>
          </div>
          <span class="text">
            <span>{{summary||0}}</span>
            <i>总积分</i>
          </span>
        </el-card>
        <el-card class="box-card">
          <div class="card-icon">
            <i class="el-icon-notebook-1"></i>
          </div>
          <span class="text">
            <span>{{ranking||0}}</span>
            <i>排名</i>
          </span>
        </el-card>
      </div>
    </div>-->
    <div v-if="topicType == 0">
      <!-- 活动统计 -->
      <el-card class="box-card">
        <p class="title">
          <span></span>活动统计
        </p>
        <Activitystatisticss
          :searchClassId="searchClassId"
          :status="status"
          :currentPage="currentPage"
        ></Activitystatisticss>
      </el-card>
      <!-- 变式内化率 -->
      <el-card class="box-card" style="margin-bottom: 30px">
        <p class="title">
          <span></span>变式内化率
        </p>
        <!-- <p>某变式班级情况分析</p> -->
        <div ref="chart" :style="{ width: '100%', height: '600px' }">
          <el-empty description="暂无数据!"></el-empty>
        </div>
      </el-card>
      <!-- 积分统计 -->
      <el-card class="box-card" v-if="showTabel !== 0">
        <p class="title">
          <span></span>班级积分统计
        </p>
        <el-table
          :data="integralData.list"
          style="width: 100%"
          border
          @cell-click="clickIntegralData"
        >
          <el-table-column prop="number" label="排序编号"></el-table-column>
          <el-table-column prop="fullName" label="学生姓名"></el-table-column>
          <el-table-column prop="summary" label="累计汇总积分"></el-table-column>
          <el-table-column prop="accomplish" label="完成积分（累计）"></el-table-column>
          <el-table-column prop="star" label="明星积分（累计）"></el-table-column>
          <el-table-column prop="win" label="优胜积分（累计）"></el-table-column>
          <el-table-column prop="help" label="帮扶积分（累计）"></el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="currentPageIntegral"
          :page-size="pagesize"
          :total="integralDataTotal"
          @current-change="changeintegral"
          class="pager"
        ></el-pagination>
      </el-card>
    </div>
    <div v-if="topicType == 1 && immexChart">
      <chartIMMEXDetail :paramObj="paramObj"></chartIMMEXDetail>
    </div>
    <el-dialog :title="integralUserName" :visible.sync="integralDetails">
      <el-table :data="gridData" height="288" @row-click="integralJump">
        <el-table-column property="title" label="活动名称"></el-table-column>
        <el-table-column property="accomplish" label="完成积分"></el-table-column>
        <el-table-column property="star" label="明星积分"></el-table-column>
        <el-table-column property="win" label="优胜积分"></el-table-column>
        <el-table-column property="help" label="帮扶积分"></el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="integralDialog.currentPageIntegral"
        :page-size="integralDialog.pagesize"
        :total="integralDialog.integralDataTotal"
        @current-change="integralDialogChange"
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { classList, activeList, taskAnalysis, SpecialNPCasesList, IntegralStatistics, SpecialList, StudentTask, getSpecialtopic, analysisSourceIntegral } from "../../api/api";
import chartIMMEXDetail from "../../components/immexDetails/index.vue";
import Activitystatisticss from "./components/Activitystatistics.vue";
export default {
  data () {
    return {
      topicTypeOptions: [{
        label: 'K12',
        value: 0
      }, {
        label: 'IMMEX',
        value: 1
      }],
      classOptions: [],
      searchClassId: 0,
      integralData: [],
      tableData1: [],
      options: [
        {
          value: "0",
          label: "K12",
        },
      ],
      activityOptions: [],
      activityValue: '',
      topicType: 0,
      showScreen: true,
      studentsEchartsData: [],
      pagesize: 10,
      currentPage: 1,
      currentPageIntegral: 1,
      studentDetails: {},
      variantType: [],
      showCascader: true,
      cascader: null,
      paramObj: {},
      studentSurvey: {},
      ranking: "",
      summary: "",
      immexOptions: {},
      immexValue: null,
      immexChart: false,
      chart: null,
      subjectArr: [],
      goodStyle: "rgb(0," + ((Math.random() * 64 + 128) >> 0) + ",0)",
      badStyle: "rgb(0," + ((Math.random() * 64 + 0) >> 0) + ",0)",
      treeDepth: 10,
      integralDataTotal: null,
      integralDetails: false,
      integralUserName: "",
      gridData: [],
      integralDialog: {
        currentPageIntegral: 1,
        pagesize: 5,
        integralDataTotal: null,
      },
      appIdUser: null,
      loading: true,
      showTabel: 1,
      status: 0,//0  老师  1  学生
    };
  },

  components: {
    chartIMMEXDetail,
    Activitystatisticss,
  },
  created () {
    if (this.$route.query) {
      this.studentDetails = this.$route.query;
    }
  },
  watch: {
    searchClassId (val, old) {
      if (val !== old) {
        // let classVal = 
      }
    }
  },
  mounted () {
    this.loadClass();
    this.drawK12Tree();
    this.analysis();
  },
  methods: {
    // 选择活动类型
    changeType (val) {
      if (val == 1) {
        let param = {
          pageNum: null,
          pageSize: null,
          classId: this.searchClassId,
          stemType: null,
        }
        activeList(param).then(res => {
          if (res.code == 0) {
            let arr = []
            res.data.list.map(item => {
              if (item.stemType == 1) {
                arr.push(item)
              }
            })
            this.activityOptions = arr
          }
        })
      }
    },
    // 积分详情分页
    integralDialogChange (val) {
      this.integralDialog.currentPageIntegral = val;
      this.loadIntegral(this.studentDetails);
    },
    // 解析appIds
    analysis () {
      if (this.utils.identity('K12做题')) {
        this.status = 1
        this.topicType = 0
      } else if (this.utils.identity('IMX做题')) {
        this.status = 1
        this.topicType = 1
      } else {
        this.showScreen = false;
      }
    },
    // 点击积分详情跳转到数据页面
    integralJump (val) {
    },
    // 打开排行榜
    clickIntegralData (val) {
      if (!this.showScreen) {
        return;
      }
      this.integralDialog.currentPageIntegral = 1;
      this.studentDetails = val;
      this.loadIntegral(val);
      this.integralDetails = true;
      this.integralUserName = val.fullName;
    },
    // 点击积分表格显示详情
    loadIntegral (val) {
      this.gridData = [];
      let param = {
        pageNum: this.integralDialog.currentPageIntegral,
        pageSize: this.integralDialog.pagesize,
        classId: this.searchClassId,
        userId: val.userId,
      };
      analysisSourceIntegral(param).then((res) => {
        if (res.code == 0) {
          this.gridData = res.data.list;
          this.integralDialog.integralDataTotal = res.data.total;
        }
      });
    },
    // 积分分页
    changeintegral (val) {
      this.currentPageIntegral = val;
      this.integral();
    },
    // 活动统计分页
    changeTableData (val) {
      this.currentPage = val;
      this.loadSpecialList();
    },
    // 选中班级id
    selectClassId (val) {
      this.searchClassId = val;
      this.loadStudent();
      let classVal = this.classOptions.find(item => { return item.id == this.searchClassId })
      this.showTabel = classVal.rankingTableType
      this.integral();
      this.loadSpecialList();
      this.loadStudentsList();
      this.getIMMXUserCompleteCase(this.searchClassId);
      this.changeType(this.topicType)
    },
    // 学生任务列表
    loadStudent () {
      if (this.status == 0) {
        var param = {
          classId: Number(this.$route.query.classId),
          userId: Number(this.$route.query.userId),
        };
      } else {
        var param = {
          classId: this.searchClassId,
        };
      }
      StudentTask(param).then((res) => {
        this.studentSurvey = res.data;
      });
    },
    noCompleted () {
      this.$router.push({
        path: "/activity",
        query: { idType: "未完成", classId: this.searchClassId },
      });
    },
    completed () {
      this.$router.push({
        path: "/activity",
        query: { idType: "已完成", classId: this.searchClassId },
      });
    },
    // 积分统计
    integral () {
      this.loading = true;
      if (this.status == 0) {
        let param1 = {
          classId: this.searchClassId,
          pageNum: this.currentPageIntegral,
          pageSize: null,
        };
        IntegralStatistics(param1).then((res) => {
          this.loading = false;
          res.data.list.forEach((item, index) => {
            if (item.userId == this.studentDetails.userId) {
              this.ranking = index + 1;
            }
          });
        });
        let param = {
          classId: this.searchClassId,
          pageNum: this.currentPageIntegral,
          pageSize: this.pagesize,
        };
        IntegralStatistics(param).then((res) => {
          if (res.data) {
            res.data.list.map((item, index) => {
              item["number"] = index + 1;
            });
            this.integralDataTotal = res.data.list[0].total;
            this.integralData = res.data;
            res.data.list.forEach((item, index) => {
              if (item.userId == Number(this.$route.query.userId)) {
                this.summary = item.summary;
                this.ranking = index + 1;
              }
            });
          } else {
            this.integralData = [];
          }
        });
      } else {
        if (this.showTabel == 2) {
          var param = {
            classId: this.searchClassId,
            pageNum: null,
            pageSize: null,
          };
        } else {
          var param = {
            classId: this.searchClassId,
            pageNum: this.currentPageIntegral,
            pageSize: this.pagesize,
          };
        }
        IntegralStatistics(param).then((res) => {
          if (res.data) {
            res.data.list.map((item, index) => {
              item["number"] = index + (this.pagesize * this.currentPageIntegral - this.pagesize) + 1;
            });
            res.data.list.sort(this.compare("summary"));
            if (this.showTabel == 2) {
              var urlAppId = window.location.href.split('=')[2].split('#')[0]
              // 本地储存租户的信息
              let appIds = JSON.parse(localStorage.getItem('appIds'))
              let goal_appIds = appIds.find(item => {
                return item.appId == urlAppId
              })
              let user = res.data.list.find(item => {
                return item.userId == Number(goal_appIds.userId)
              })
              user.number = 1
              let arr = []
              arr.push(user)
              this.integralData['list'] = arr
            } else {
              this.integralData = res.data;
              this.integralDataTotal = res.data.total;
            }
            res.data.list.forEach((item, index) => {
              if (item.userId == this.appIdUser) {
                this.summary = item.summary;
                this.ranking = index + 1;
              }
            });
          } else {
            this.integralData = [];
          }
        });
      }
    },
    // 学生积分排行
    compare (val) {
      return function sortStudent (a, b) {
        let value1 = a[val];
        let value2 = b[val];
        return value2 - value1;
      };
    },
    // 加载学生图表
    async loadStudentsList () {
      if (this.status == 0) {
        var param = {
          classId: Number(this.$route.query.classId),
          userId: Number(this.$route.query.userId),
        };
      } else if (this.status == 1) {
        var param = {
          classId: this.searchClassId,
        };
      }

      await SpecialNPCasesList(param).then((res) => {
        if (res.code == 0) {
          let arr = [];
          let arrVariant = [];
          res.data.list.map((item) => {
            let arr2 = [];
            let obj = {
              type: "line",
              stack: "总量",
              itemStyle: {
                color: "#" + Math.floor(Math.random() * 16777215).toString(16),
              },
            };
            item.npCasesLists.map((itm) => {
              let arr1 = [];
              arrVariant.push(itm.name);
              arr1.push(itm.name);
              arr1.push((itm.accuracy * 100).toFixed(2));
              arr2.push(arr1);
            });
            obj["name"] = item.special;
            obj["data"] = arr2;
            arr.push(obj);
          });
          this.studentsEchartsData = arr;
          this.Variant = arrVariant;
          this.drawLine();
        } else {
          this.$message.error(res.message)
        }
      });
    },
    // 返回上一级
    goUpperLevel () {
      this.$router.go(-1);
    },
    // 班级内任务概况
    taskSurvey () {
      taskAnalysis({ classId: this.searchClassId }).then((res) => {
        this.survey = res.data;
      });
    },
    // 获取学生活动统计
    loadSpecialList () {
      if (this.status == 1) {
        var param = {
          pageNum: this.currentPage,
          pageSize: null,
          classId: this.searchClassId,
        };
        var param1 = {
          pageNum: this.currentPage,
          pageSize: this.pagesize,
          classId: this.searchClassId,
        };
      } else if (this.status == 0) {
        var param = {
          pageNum: this.currentPage,
          pageSize: null,
          classId: Number(this.$route.query.classId),
          userId: Number(this.$route.query.userId),
        };
        var param1 = {
          pageNum: this.currentPage,
          pageSize: this.pagesize,
          classId: Number(this.$route.query.classId),
          userId: Number(this.$route.query.userId),
        };
      }
      // 获取所有表头,拼接表头 
      SpecialList(param).then((res) => {
        if (res.data) {
          let arr = [];
          res.data.list.map((item) => {
            arr.push(item.special);
          });
          arr = [...new Set(arr)];
          this.subjectArr = arr;
        }
      });
      // 分页获取活动数据
      SpecialList(param1).then((res) => {
        if (res.data) {
          let arrActiveId = [];
          res.data.list.map((item) => {
            if (item.accuracy !== 0) {
              item.accuracy = (item.accuracy * 100).toFixed(2);
            }
          });
          res.data.list.map((item) => {
            let arr = [];
            let obj = {};
            obj["special1"] = item.special;
            obj["arrange"] = item.arrange;
            obj["accuracy"] = item.accuracy;
            obj["completed"] = item.completed;
            arr.push(obj);
            item["children"] = arr;
            arrActiveId.push(item.activeId);
          });
          arrActiveId = [...new Set(arrActiveId)];
          arrActiveId.forEach((item, index) => {
            let arr = [];
            res.data.list.forEach((itm) => {
              let obj = {};
              if (Number(item) == Number(itm.activeId)) {
                obj["special1"] = itm.special;
                obj["arrange"] = itm.arrange;
                obj["accuracy"] = itm.accuracy;
                obj["completed"] = itm.completed;
                arr.push(obj);
              }
            });
            res.data.list.map((it) => {
              if (Number(it.activeId) == Number(item)) {
                it.children = arr;
              }
            });
          });
          let result = [];
          let obj1 = {};
          for (var i = 0; i < res.data.list.length; i++) {
            if (!obj1[res.data.list[i].activeId]) {
              result.push(res.data.list[i]);
              obj1[res.data.list[i].activeId] = true;
            }
          }
          res.data.list = result;
          // ----------------------
          // 去重
          res.data.list.map((item) => {
            let obj = {};
            let peon = item.children.reduce((cur, next) => {
              obj[next.special1]
                ? ""
                : (obj[next.special1] = true && cur.push(next));
              return cur;
            }, []);
            item.children = peon;
          });
          this.tableData1 = res.data;
        }
      });
    },
    // 获取班级
    loadClass () {
      classList({}).then((res) => {
        this.classOptions = res.data.list;
        this.searchClassId = this.classOptions[0].id;
        this.getIMMXUserCompleteCase(this.searchClassId);
        this.integral();
        this.showTabel = this.classOptions[0].rankingTableType
        this.loadSpecialList();
        this.loadStudent();
        this.loadStudentsList();
      });
    },
    drawLine () {
      if (this.Variant.length > 0) {
        //2.初始化
        this.chart = this.$echarts.init(this.$refs.chart);
        //3.配置数据
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            icon: "rect",
            itemWidth: 15,
            itemHeight: 15,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              interval: 0,
              rotate: -30,
            },
            data: this.Variant,
          },
          yAxis: {
            type: "value",
            name: "内化率(%)",
            max: 100,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              show: false,
            },
          },
          series: this.studentsEchartsData,
        };
        // 4.传入数据
        this.chart.setOption(option);
      }
    },
    // 创建活动选中类型
    choiceActivity (val) {
      if (val == 1) {
        var div = document.getElementById("draw_tree_canvas");
        div.parentNode.removeChild(div);
      } else {
        this.$nextTick(() => this.drawK12Tree());
      }
      this.immexValue = null;
      this.immexChart = false;
      setTimeout(() => {
        if (val == 0) {
          this.drawLine();
        }
      }, 500);
      this.cascader = null;
      let param = {};
      if (val == 0) {
        this.showCascader = true;
        param = {
          grade: 7,
          subject: "1",
          specialtopicType: Number(val),
        };
      } else {
        this.showCascader = false;
        param = {
          grade: 5,
          subject: "12",
          specialtopicType: Number(val),
        };
      }
      getSpecialtopic(param).then((res) => {
        if (this.showCascader) {
          res.data.map((item) => {
            item["label"] = item.chapterTitle;
            item["children"] = item.npCases;
            item.children.map((itm) => {
              itm["label"] = itm.caseSN;
            });
          });
          let arr = [];
          res.data.forEach((item) => {
            item["value"] = item.id;
            item.children.map((itm) => {
              itm["value"] = itm.id;
              itm.children = undefined;
            });
            arr.push(item);
          });
          this.variantType = arr;
        } else {
          res.data.map((item) => {
            item["label"] = item.psname;
            item["children"] = item.caseEntities;
            item.children.map((itm, index) => {
              itm["label"] = item.psname + itm.caseSN;
            });
          });
          let arr = [];
          res.data.forEach((item) => {
            item["value"] = item.id;
            item.children.map((itm) => {
              itm["value"] = itm.id;
              itm.children = undefined;
            });
            arr.push(item);
          });
          this.variantType = arr;
        }
      });
    },
    getIMMXUserCompleteCase (classId) {

      let params = {
        caseType: 1,
        classId: classId,
      };
      this.$api.page.getIMMXUserCompleteCase(params).then((res) => {
        if (res.data.code == 0) {
          this.immexValue = null;
          this.immexOptions = res.data.data;
          this.immexSelect()
        }
      });
    },
    immexSelect (val) {
      if (this.immexOptions.length > 0) {
        this.paramObj = this.immexOptions[0];
        this.paramObj.classId = this.searchClassId;
        this.immexChart = true;
      }
    },
    drawK12Tree () {
      var div = document.getElementById("student-tree");
      var canvas = document.createElement("canvas");
      canvas.id = "draw_tree_canvas";
      var ctx = canvas.getContext("2d");
      if (div !== null) {
        canvas.width = div.offsetWidth;
        canvas.height = div.offsetHeight;
        div.appendChild(canvas);
      }

      this.renderTree(
        ctx,
        canvas.width / 2,
        canvas.height - 10,
        110,
        -Math.PI / 2,
        10,
        20,
        1
      );
    },
    renderTree (ctx, startX, startY, length, angle, depth, branchWidth, value) {
      var rand = Math.random,
        newLength,
        newAngle,
        newDepth,
        maxBranch = 3,
        endX,
        endY,
        maxAngle = (2 * Math.PI) / 4,
        subBraches;
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      endX = startX + length * Math.cos(angle);
      endY = startY + length * Math.sin(angle);
      ctx.lineCap = "round";
      ctx.lineWidth = branchWidth;
      ctx.lineTo(endX, endY);
      if (depth == 9) {
        value = rand() * 10;
      }
      if (depth <= 5) {
        ctx.strokeStyle = value > 5 ? this.goodStyle : this.badStyle;
      } else {
        ctx.strokeStyle = "rgb(" + ((rand() * 64 + 64) >> 0) + ",50,25)";
      }
      ctx.stroke();
      newDepth = depth - 1;
      if (!newDepth) return;
      var subBranches = rand() * (maxBranch - 1) + 1;
      branchWidth *= 0.7;
      for (var i = 0; i < subBranches; i++) {
        newAngle = angle + rand() * maxAngle - maxAngle * 0.5;
        newLength = length * (0.7 + rand() * 0.3);
        this.renderTree(
          ctx,
          endX,
          endY,
          newLength,
          newAngle,
          newDepth,
          branchWidth,
          value
        );
      }
    },
  },
};
</script>
<style lang='less' scoped>
@import '../../style/chartManage/index.less';
@import '../../style/mouse.less';
.screen {
  margin-bottom: 20px;
}
.sel {
  margin-right: 30px;
}
.top_card {
  display: flex;
  margin-top: 30px;
  .card {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    background: #fff;
    margin-right: 40px;
    height: 130px;
    align-items: center;
    .card_l {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
    }
    .box-card-title {
      display: flex;
      flex-direction: column;
      span {
        display: inline-block;
        color: #888383;
        margin: 5px 0;
        &:first-child {
          font-size: 30px;
          font-weight: 600;
          color: black;
        }
      }
    }
  }
}
.card-header-external {
  width: 25%;
  display: inline-block;
  padding-left: 20px;
}
.box-card-student {
  margin-top: 30px;
  width: 100%;
  position: relative;
  display: inline-block;
  .title {
    margin-bottom: 20px;
    &:before {
      content: '';
      width: 4px;
      height: 22px;
      display: inline-block;
      background: #367ee9;
      vertical-align: bottom;
      margin-right: 5px;
    }
  }
}
.box-card {
  margin-top: 30px;
  .title {
    margin-bottom: 20px;
    &:before {
      content: '';
      width: 4px;
      height: 22px;
      display: inline-block;
      background: #367ee9;
      vertical-align: bottom;
      margin-right: 5px;
    }
  }
}
.pager {
  text-align: center;
  margin: 20px 0;
}
.el-dialog {
  .el-pagination {
    display: inline-block;
    position: relative;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
